import {endOfDay, endOfMonth, endOfYear, startOfDay, startOfMonth, startOfYear, subDays, subMonths, subYears} from "date-fns";

export default [
	{
		label: 'Today',
		range: [startOfDay(new Date()), endOfDay(new Date())],
		previousRange: [startOfDay(subDays(new Date(), 1)), endOfDay(subDays(new Date(), 1))]
	},
	{
		label: 'Yesterday',
		range: [startOfDay(subDays(new Date(), 1)), endOfDay(subDays(new Date(), 1))],
		previousRange: [startOfDay(subDays(new Date(), 2)), endOfDay(subDays(new Date(), 2))],
	},
	{
		label: 'Last 7 days',
		range: [startOfDay(subDays(new Date(), 7)), endOfDay(new Date())],
		previousRange: [startOfDay(subDays(new Date(), 14)), startOfDay(subDays(new Date(), 7))],
	},
	{
		label: 'Last 30 days',
		range: [startOfDay(subDays(new Date(), 30)), endOfDay(new Date())],
		previousRange: [startOfDay(subDays(new Date(), 60)), startOfDay(subDays(new Date(), 30))],
	},
	{
		label: 'This month',
		range: [startOfMonth(new Date()), endOfMonth(new Date())],
		previousRange: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
	},
	{
		label: 'Last month',
		range: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
		previousRange: [startOfMonth(subMonths(new Date(), 2)), endOfMonth(subMonths(new Date(), 2))],
	},
	{
		label: 'Last 12 months',
		range: [startOfMonth(subMonths(new Date(), 12)), endOfDay(new Date())],
		previousRange: [startOfMonth(subMonths(new Date(), 24)), startOfMonth(subMonths(new Date(), 12))],
	},
	{
		label: 'This year',
		range: [startOfYear(new Date()), endOfYear(new Date())],
		previousRange: [startOfYear(subYears(new Date(), 1)), endOfYear(subYears(new Date(), 1))],
	},
	{
		label: 'Last year',
		range: [startOfYear(subYears(new Date(), 1)), endOfYear(subYears(new Date(), 1))],
		previousRange: [startOfYear(subYears(new Date(), 2)), endOfYear(subYears(new Date(), 2))],
	},
];
