<template>
	<div :dusk="name">
		<template v-if="hasLabelSlot">
			<label :for="computedId" class="block mb-1 text-sm font-medium text-jmi-purple1">
				<slot name="label" />
			</label>
		</template>
		<template v-else>
			<label v-if="label" :for="computedId" class="block mb-1 text-sm font-medium text-jmi-purple1">
				<span>{{ label }}</span> <span v-if="required" class="text-jmi-coral1">*</span>
			</label>
			<slot name="sub-label" />
		</template>
		<div :class="['relative rounded-md flex-grow', {'shadow-sm': !isCheckbox}]">
			<slot name="prefix" />

			<FormsInternalSwitch
				v-if="type === 'switch'"
				v-model="computedModelValue"
				:label="description"
				:class="inputClass"
			/>
			<template v-else-if="type === 'checkbox'">
				<input
					:id="computedId"
					v-model="computedModelValue"
					:type="type"
					:name="name"
					:autocomplete="autocomplete"
					:placeholder="placeholder"
					:pattern="pattern"
					:readonly="readonly"
					:disabled="disabled"
					:class="classes"
				>
				<label v-if="description" :for="computedId" class="ml-4 cursor-pointer text-sm font-medium text-jmi-purple2">{{ description }}</label>
			</template>
			<FormsInternalDateRange
				v-else-if="type === 'date-range'"
				v-model="computedModelValue"
				:class="inputClass"
				:placeholder="placeholder"
			/>
			<textarea
				v-else-if="type === 'textarea'"
				:id="computedId"
				v-model="computedModelValue"
				:name="name"
				:placeholder="placeholder"
				:required="!!required"
				:readonly="readonly"
				:disabled="disabled"
				:class="classes"
				@keydown="startKeyPress"
				@keyup="endKeyPress"
				@focus="$emit('focus', $event)"
				@blur="$emit('blur', $event)"
			/>
			<input
				v-else
				:id="computedId"
				v-model="computedModelValue"
				:type="type"
				:name="name"
				:autocomplete="autocomplete"
				:placeholder="placeholder"
				:required="!!required"
				:pattern="pattern"
				:readonly="readonly"
				:disabled="disabled"
				:min="computedMin"
				:max="computedMax"
				:class="classes"
				@keydown="startKeyPress"
				@keyup="endKeyPress"
				@focus="$emit('focus', $event)"
				@blur="inputBlur($event)"
				@click="customInputAction($event)"
			>
			<div v-if="computedErrorMessage && type !== 'switch' && hasUserBlurred" :class="['absolute inset-y-0 right-0 pr-3 pointer-events-none', {'flex items-center': type !== 'textarea', 'pt-3': type === 'textarea'}]">
				<ExclamationCircleIcon class="h-5 w-5 text-jmi-coral1" aria-hidden="true" />
			</div>
			<slot name="suffix" />
		</div>
		<p v-if="computedErrorMessage && hasUserBlurred" :id="computedId + '_error'" class="mt-2 text-sm text-jmi-coral1">
			{{ computedErrorMessage }}
		</p>
	</div>
</template>

<script setup lang="ts">
import {v4 as uuidv4} from 'uuid';
import * as yup from "yup";
import {ExclamationCircleIcon} from "@heroicons/vue/24/solid";
import {FieldMeta, useField} from 'vee-validate';
import shouldTrackPerformance from "~/utils/shouldTrackPerformance";
import {computed, PropType, onBeforeMount, ref, Ref, onMounted, useSlots, watch} from "vue";
import type {BooleanSchema, DateSchema, StringSchema} from "yup";
import {format} from "date-fns";
import {DateRange, isDateSchema} from "~/utils/types";

type AcceptedInputSchemas = DateSchema<Date | undefined | null> | StringSchema<string | undefined | null> | BooleanSchema;

const props = defineProps({
	type: {
		type: String as PropType<"switch" | "date-range" | "checkbox" | "textarea" | "text" | "password" | "date" | "email" | "number">,
		required: false,
		default: 'text'
	},
	id: {
		type: String,
		required: false,
		default: undefined
	},
	name: {
		type: String,
		required: false,
		default: undefined
	},
	label: {
		type: String,
		required: false,
		default: undefined
	},
	description: {
		type: String,
		required: false,
		default: undefined
	},
	placeholder: {
		type: String,
		required: false,
		default: undefined
	},
	modelValue: {
		type: [String, Boolean, Array, null] as PropType<string | boolean | null | DateRange>,
		required: false,
		default: null
	},
	required: {
		type: [Boolean, String],
		required: false,
		default: false,
	},
	extendSchema: {
		type: Function as PropType<(obj: AcceptedInputSchemas) => AcceptedInputSchemas>,
		required: false,
		default: undefined
	},
	readonly: Boolean,
	disabled: Boolean,
	error: {
		type: [String, Array],
		required: false,
		default: undefined
	},
	errorLabel: {
		type: String,
		required: false,
		default: undefined
	},
	inputClass: {
		type: String,
		required: false,
		default: undefined
	},
	autocomplete: {
		type: String,
		required: false,
		default: undefined
	},
	min: {
		type: [Date, Number] as PropType<Date | number>,
		required: false,
		default: undefined,
	},
	max: {
		type: [Date, Number] as PropType<Date | number>,
		required: false,
		default: undefined,
	},
	isTouched: {
		type: Boolean,
		required: false,
		default: undefined
	},
	dirty: {
		type: Boolean,
		required: false,
		default: false,
	},
	useBlurValidation: {
		type: Boolean,
		required: false,
		default: false
	},
	pattern: {
		type: String,
		required: false,
		default: undefined
	},
});

const slots = useSlots();
const emit = defineEmits(['update:modelValue', 'update:dirty', 'focus', 'blur']);

const computedId = computed(() => {
	return props.id ? props.id : uuidv4();
});

const hasLabelSlot = computed(() => {
	return !!slots.label;
});

const isCheckbox = computed(() => ['switch', 'checkbox'].indexOf(props.type) !== -1);

const classes = computed(() => {
	if (isCheckbox.value) {
		return [
			props.inputClass,
			'h-4 w-4 rounded border-jmi-purple4 text-jmi-purple1 focus:ring-jmi-purple3 cursor-pointer',
			{'bg-jmi-purple5': props.disabled || props.readonly},
			{'cursor-not-allowed': props.disabled},
		];
	} else {
		return [
			props.inputClass,
			'block w-full px-3 py-2 border border-jmi-purple4 rounded-md placeholder-jmi-purple3 focus:outline-none focus:ring-jmi-purple3 focus:border-jmi-purple3 sm:text-sm ',
			{'bg-jmi-purple5': props.disabled || props.readonly},
			{'cursor-not-allowed': props.disabled},
			{'cursor-pointer': props.readonly},
		];
	}
});

const computedMin = computed(() => {
	if (props.type == "date") {
		return props.min ? format(props.min as Date, 'yyyy-MM-dd') : undefined;
	} else {
		return props.min;
	}
});

const computedMax = computed(() => {
	if (props.type == "date") {
		return props.max ? format(props.max as Date, 'yyyy-MM-dd') : undefined;
	} else {
		return props.max;
	}
});

const computedSchema = computed(() => {
	const errorLabel = props.errorLabel ?? props.label;
	let obj: AcceptedInputSchemas;

	if (props.type === 'switch') {
		obj = yup.boolean().required();

		if (props.required) {
			// VeeValidate doesn't support boolean fields, so we need to add a custom error message
			obj = obj.oneOf([true], typeof props.required === "string" ? props.required : 'This field is required');
		}
	} else {
		if (props.type == "date") {
			obj = yup.date().typeError(`The ${errorLabel} must be a valid date`);
		} else {
			obj = yup.string();

			if (props.type == "email") {
				obj = obj.email();
			}
		}

		obj = obj.nullable();

		if (errorLabel) {
			obj = obj.label(errorLabel);
		}

		if (props.required) {
			obj = obj.required(typeof props.required === "string" ? props.required : undefined);
		}

		if (isDateSchema(obj)) {
			if (props.min) {
				obj = obj.min(props.min as Date, `The ${errorLabel} must be on or after ${format(props.min, "do MMMM yyyy")}`);
			}

			if (props.max) {
				obj = obj.max(props.max as Date, `The ${errorLabel} must be on or before ${format(props.max, "do MMMM yyyy")}`);
			}
		} else {
			if (props.min) {
				obj = obj.min(props.min as number);
			}

			if (props.max) {
				obj = obj.max(props.max as number);
			}
		}

	}

	if (props.extendSchema) {
		obj = props.extendSchema(obj);
	}

	return obj;
});

const computedModelValue = computed({
	get() {
		return props.modelValue;
	},
	set(new_value) {
		if (props.type === "date" && new_value === '') {
			new_value = null;
		}
		emit('update:modelValue', new_value);
	}
});

const computedErrorMessage = computed<string | undefined>(() => {
	if (props.error) {
		if (Array.isArray(props.error)) {
			return props.error.length ? props.error.join(" ") : undefined;
		} else {
			return props.error;
		}
	} else if (meta?.touched) {
		return errorMessage.value;
	} else {
		return undefined;
	}
});

let errorMessage: Ref<string | undefined>;
let meta: FieldMeta<typeof props.modelValue> | undefined = undefined;

onBeforeMount(() => {
	if (props.name) {
		const {setValue, setTouched, resetField, errorMessage: errorMessageRef, meta: metaRef} = useField(props.name, computedSchema, {
			initialValue: props.modelValue,
		});

		meta = metaRef;
		errorMessage = errorMessageRef;

		// Reset the field when it mounts, to make sure it doesn't show stale state.
		resetField({
			value: props.modelValue,
			touched: (props.isTouched === undefined) ? false : props.isTouched
		})

		watch(() => props.modelValue, () => {
			setValue(props.modelValue);

			// Only change the touched automatically if the parent component isn't assuming control over it.
			if (props.isTouched === undefined) {
				setTouched(true);
			}
		});

		// If the parent component is controlling the isTouched, erase whatever vee-validate changes.
		if (props.isTouched !== undefined) {
			watch(() => metaRef.touched, () => {
				if (metaRef.touched !== props.isTouched) {
					setTouched(props.isTouched);
				}
			});
		}

		watch(() => props.isTouched, () => {
			if (props.isTouched !== undefined) {
				setTouched(props.isTouched);
			}
		});

		watch(() => meta?.dirty, (dirty) => {
			if (dirty) {
				emit('update:dirty', dirty);
			}
		});
	}
});
const hasUserBlurred = ref(true);

onMounted(() => {
	// Does this validator use blur validation?
	if (props.useBlurValidation && (props.modelValue === null || props.modelValue === '')) {
		hasUserBlurred.value = false;
	}
});

const startKeyPress = () => {
	if (shouldTrackPerformance()) {
		window.performance.mark("input-keypress-start");
	}
};
const endKeyPress = () => {
	if (shouldTrackPerformance()) {
		window.performance.mark("input-keypress-end");
		window.performance.measure("input-keypress", "input-keypress-start", "input-keypress-end");
	}
};

const inputBlur = (event: FocusEvent) => {
	emit('blur', event);

	if (props.useBlurValidation && (props.modelValue !== null && props.modelValue !== '')) {
		hasUserBlurred.value = true;
	}
};

const customInputAction = (event: MouseEvent) => {
	// If this is a date input, show the date picker when the user clicks on the input, so is more obvious what to do.
	if (props.type === "date") {
		event.preventDefault();
		event.stopPropagation();
		const input = event.target as HTMLInputElement;
		input.showPicker();
	}
};

</script>
