<template>
	<SwitchGroup>
		<div class="flex h-full items-center">
			<div class="w-11 flex">
				<Switch
					v-model="computedModelValue"
					:class="computedModelValue ? 'bg-jmi-purple2' : 'bg-jmi-purple4'"
					class="relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-jmi-purple3 focus:ring-offset-2"
				>
					<span
						:class="computedModelValue ? 'translate-x-6' : 'translate-x-1'"
						class="inline-block h-4 w-4 transform rounded-full bg-white transition-transform"
					/>
				</Switch>
			</div>
			<SwitchLabel v-if="label" class="ml-4 cursor-pointer text-sm font-medium text-jmi-purple2">
				{{ label }}
			</SwitchLabel>
		</div>
	</SwitchGroup>
</template>
<script setup lang="ts">
import {Switch, SwitchGroup, SwitchLabel} from "@headlessui/vue";
import {computed} from "vue";

const props = defineProps({
	label: {
		type: String,
		required: false,
	},
	modelValue: {
		type: Boolean,
		required: false,
		default: false
	}
});

const emit = defineEmits(['update:modelValue']);

const computedModelValue = computed({
	get() {
		return props.modelValue;
	},
	set(value) {
		emit('update:modelValue', value);
	}
});
</script>
