<template>
	<Datepicker
		v-model="computedModelValue"
		range
		auto-apply
		:preset-ranges="presetRanges"
		:enable-time-picker="false"
		format="dd/MM/yyyy"
		:placeholder="placeholder"
	/>
</template>
<script setup lang="ts">
import Datepicker from '@vuepic/vue-datepicker';
import '../../../assets/css/vue-datepicker.scss';
import {computed, PropType, ref} from "vue";
import presetDateRanges from "~/utils/presetDateRanges";
import type { DateRange } from "~/utils/types";
import {endOfDay, startOfDay} from "date-fns";

const props = defineProps({
	modelValue: {
		type: Array as unknown as PropType<DateRange | null>,
		required: false,
		default: null
	},
	placeholder: {
		type: String,
	}
});
const emit = defineEmits(['update:modelValue']);
const presetRanges = ref(presetDateRanges);
const computedModelValue = computed({
	get() {
		return props.modelValue;
	},
	set(value) {
		let newValue = null;

		if (value !== null) {
			newValue = [
				startOfDay(new Date(value[0])),
				endOfDay(new Date(value[1]))
			];
		}

		emit('update:modelValue', newValue);
	}
});
</script>
