<template>
	<template v-if="to">
		<NuxtLink active-class="active" :class="classes" :to="to">
			<slot />
		</NuxtLink>
	</template>
	<template v-else-if="href">
		<a :class="classes" :href="href">
			<slot />
		</a>
	</template>
	<template v-else>
		<button :disabled="disabled" :class="classes" type="button">
			<slot />
		</button>
	</template>
</template>

<script>
export default {
	props: {
		to: {
			type: String,
			default: null
		},
		href: {
			type: String,
			default: null
		},
		color: {
			type: String,
			default: 'coral'
		},
		size: {
			type: String,
			default: 'medium'
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false
		}
	},

	computed: {
		/* apply to all buttons */
		defaultButtonClasses() {
			return 'flex inline-flex justify-center border rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2';
		},

		classes() {
			if (this.disabled && ['purple', 'coral', 'green'].indexOf(this.color) === -1) {
				throw new Error(this.color + " has no disabled styles");
			}

			return [
				this.defaultButtonClasses,

				/* sizes */
				{'px-2.5 py-1.5 text-xs': this.tiny},
				{'px-3 py-2 text-sm leading-4': this.small},
				{'py-2 px-4 text-sm': this.medium},
				{'px-4 py-2 text-base': this.large},
				{'px-6 py-3 text-base': this.huge},

				/* colors */
				{'text-white bg-jmi-coral2 border-transparent hover:bg-jmi-coral1 focus:ring-jmi-coral2 disabled:bg-jmi-coral3': this.coral},
				{'text-jmi-coral1 bg-jmi-purple5 border border-jmi-coral1 hover:bg-jmi-white focus:ring-jmi-coral1': this.coralOutline},
				{'text-jmi-coral1 bg-jmi-white border border-jmi-coral1 hover:bg-jmi-purple5 focus:ring-jmi-coral1': this.lightCoralOutline},
				{'text-jmi-purple2 bg-jmi-purple4 border-transparent hover:text-jmi-purple1 focus:ring-jmi-purple4': this.lightPurple},
				{'text-jmi-purple2 bg-jmi-white border border-jmi-purple3 hover:bg-jmi-purple5 focus:ring-jmi-purple3': this.lightPurpleOutline},
				{'text-white bg-jmi-purple2 border-transparent hover:bg-jmi-purple1 focus:ring-jmi-purple2 disabled:bg-jmi-purple3': this.purple},
				{'text-jmi-purple1 bg-jmi-white border border-jmi-purple1 hover:bg-jmi-purple5 focus:ring-jmi-purple1': this.purpleOutline},
				{'text-white bg-jmi-blue1 border-transparent hover:bg-jmi-blue1 focus:ring-jmi-blue1': this.blue},
				{'text-jmi-blue1 bg-jmi-white border border-jmi-blue1 hover:bg-jmi-purple5 focus:ring-jmi-blue1': this.blueOutline},
				{'text-white bg-jmi-green2 border-transparent hover:bg-jmi-green2 focus:ring-jmi-green2 disabled:bg-jmi-green2/50': this.green},
				{'text-jmi-green2 bg-jmi-white border border-jmi-green2 hover:bg-jmi-purple5 focus:ring-jmi-green2': this.greenOutline},
				{'text-white bg-jmi-purple1 border-transparent hover:bg-jmi-purple2 focus:ring-jmi-purple1 disabled:bg-jmi-purple2': this.darkPurple},
			];
		},

		/* sizes */
		tiny() {
			return this.size === 'tiny';
		},
		small() {
			return this.size === 'small';
		},
		medium() {
			return this.size === 'medium';
		},
		large() {
			return this.size === 'large';
		},
		huge() {
			return this.size === 'huge';
		},

		/* colors */
		coral() {
			return this.color === 'coral';
		},
		coralOutline() {
			return this.color === 'coralOutline';
		},
		lightCoralOutline() {
			return this.color === 'lightCoralOutline';
		},
		lightPurple() {
			return this.color === 'lightPurple';
		},
		lightPurpleOutline() {
			return this.color === 'lightPurpleOutline';
		},
		purple() {
			return this.color === 'purple';
		},
		purpleOutline() {
			return this.color === 'purpleOutline';
		},
		blue() {
			return this.color === 'blue';
		},
		blueOutline() {
			return this.color === 'blueOutline';
		},
		green() {
			return this.color === 'green';
		},
		greenOutline() {
			return this.color === 'greenOutline';
		},
		darkPurple() {
			return this.color === 'darkPurple';
		},
	}
};
</script>
